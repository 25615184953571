import { IcoCog, IcoDotsHorizontal, IcoLinkSlash } from '@components/icons';
import { BtnSecondary } from '@components/buttons';
import { Pill } from '@components/pill';
import { Dropdown, MenuItem } from '@components/dropdown';

type Props = {
  name: string;
  description: string;
  isInstalled: boolean;
  icon: JSX.Element;
  onIntegrate: () => void;
  onDisconnect: () => void;
};

export function IntegrationRow({
  name,
  description,
  isInstalled,
  icon,
  onIntegrate,
  onDisconnect,
}: Props) {
  return (
    <div class="flex justify-between items-center p-4 bg-white rounded-2xl">
      <div class="flex items-center gap-4">
        <div class="bg-indigo-500 text-indigo-500 rounded-full p-2">{icon}</div>
        <div class="flex flex-col">
          <div class="flex gap-2 font-semibold">
            {name}
            {isInstalled && <Pill color="green">Installed</Pill>}
          </div>
          <div class="text-sm text-gray-500">{description}</div>
        </div>
      </div>
      {!isInstalled && (
        <BtnSecondary class="rounded-full px-8" onClick={onIntegrate}>
          Install
        </BtnSecondary>
      )}
      {isInstalled && (
        <Dropdown
          triggerClass="max-w-full"
          hideDownIcon
          noPadding
          class="max-w-full"
          renderMenu={() => (
            <div class="p-2 gap-2 flex flex-col">
              <MenuItem onClick={onIntegrate}>
                <IcoCog class="w-4 h-4 mr-2" />
                Re-integrate
              </MenuItem>
              <MenuItem onClick={onDisconnect}>
                <span class="flex gap-2 text-red-500">
                  <IcoLinkSlash />
                  Disconnect
                </span>
              </MenuItem>
            </div>
          )}
        >
          <IcoDotsHorizontal class="w-5 h-5 text-gray-500" />
        </Dropdown>
      )}
    </div>
  );
}
