import { RuzcalMgmtPage } from './mgmt-page';
import { PageContent, PageHeading, PageSection } from './common';
import { AsyncForm, FormGroup } from '@components/async-form';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { rpx } from 'client/lib/rpx-client';
import { showToast } from '@components/toaster';
import { useMemo, useState } from 'preact/hooks';
import { useDebouncedEffect } from 'client/utils/debounce';
import { serialAsync } from 'client/utils/serial-async';
import { showError } from '@components/app-error';
import { IcoCheck, IcoChevronDown, IcoExclamation, IcoExternalLink } from '@components/icons';
import { Spinner } from '@components/spinner';
import { TimezoneModal } from '@components/timezone-modal';
import { showModalForm } from '@components/modal-form';
import { UserProfileIconChanger } from '@components/avatars';
import { ComponentChildren } from 'preact';
import { showChangePasswordForm } from '@components/password-form';
import { LoadedProps, RouteLoadProps, defRoute } from '@components/router';
import { InputField, eventToState } from './form-helpers';

export const route = defRoute({ authLevel: 'superadmin', load, Page });

async function load(route: RouteLoadProps) {
  const host = await rpx.ruzcal.ensureHost();
  return {
    urlPrefix: host.urlPrefix,
    externalIcalUrl: host.externalIcalUrl,
    user: route.auth.user!,
  };
}

function useURLVerifier(urlPrefix: string) {
  const [status, setStatus] = useState<{
    status: 'loading' | 'conflict' | 'verified';
    message?: string;
  }>({ status: 'verified', message: '' });
  const serialLoad = useMemo(
    () =>
      serialAsync(async (urlPrefix: string) => {
        try {
          const result = await rpx.ruzcal.isURLPrefixAvailable({ urlPrefix });
          await new Promise((r) => setTimeout(r, 1000));
          setStatus({
            status: result?.error ? 'conflict' : 'verified',
            message: result?.error,
          });
        } catch (err) {
          showError(err);
        }
      }),
    [],
  );

  useDebouncedEffect(() => {
    setStatus({ status: 'loading' });
    serialLoad(urlPrefix);
  }, [urlPrefix]);
  return status;
}

function SectionHeading({ children }: { children: ComponentChildren }) {
  return <h2 class="font-semibold text-lg border-t border-gray-300 pt-4">{children}</h2>;
}

function Page(props: LoadedProps<typeof load>) {
  const { state, setState } = props;
  const [isSaving, setIsSaving] = useState(false);
  const { status, message } = useURLVerifier(state.urlPrefix);

  return (
    <RuzcalMgmtPage title="Settings" currentPage="other">
      <PageContent>
        <PageSection>
          <PageHeading title="Settings" subtitle="Manage your calendar settings and profile." />
          <AsyncForm
            class="flex flex-col gap-8 w-lg"
            onSubmit={async () => {
              setIsSaving(true);
              try {
                await rpx.auth.updateUser({
                  timezone: state.user.timezone,
                  email: state.user.email,
                  name: state.user.name,
                  id: state.user.id,
                  bio: state.user.bio,
                  displayName: state.user.displayName,
                });
                await rpx.ruzcal.saveHost({
                  urlPrefix: state.urlPrefix,
                  externalIcalUrl: state.externalIcalUrl,
                });
                showToast({
                  title: 'Settings saved',
                  type: 'ok',
                  message: 'Your settings have been saved.',
                });
              } finally {
                setIsSaving(false);
              }
            }}
          >
            <SectionHeading>Profile</SectionHeading>
            <div>
              <UserProfileIconChanger
                user={state.user}
                onChange={(profilePhotoUrl) =>
                  setState((s) => ({ ...s, user: { ...s.user, profilePhotoUrl } }))
                }
              />
            </div>
            <FormGroup prop="name">
              <label class="flex flex-col gap-1">
                <span class="font-medium flex flex-col">Name</span>
                <span class="relative flex">
                  <input
                    type="text"
                    name="name"
                    class="inline-ruz-input px-4 p-3 text-sm"
                    value={state.user.name}
                    autofocus
                    autocomplete="off"
                    onInput={(e: any) =>
                      setState((s) => ({
                        ...s,
                        user: { ...s.user, name: e.target.value },
                      }))
                    }
                  />
                </span>
              </label>
            </FormGroup>
            <FormGroup prop="email">
              <label class="flex flex-col gap-1">
                <span class="font-medium flex flex-col">Email</span>
                <span class="relative flex">
                  <input
                    type="text"
                    name="email"
                    class="inline-ruz-input px-4 p-3 text-sm"
                    value={state.user.email}
                    autofocus
                    autocomplete="off"
                    onInput={(e: any) =>
                      setState((s) => ({
                        ...s,
                        user: { ...s.user, email: e.target.value },
                      }))
                    }
                  />
                </span>
              </label>
            </FormGroup>
            <FormGroup prop="timezone">
              <label class="flex flex-col gap-1">
                <span class="font-medium flex flex-col">Timezone</span>
                <BtnSecondary
                  class="flex justify-between items-center gap-4 bg-white p-4"
                  onClick={(e: any) =>
                    showModalForm(({ resolve }) => (
                      <TimezoneModal
                        timezone={state.user.timezone}
                        hide={() => {
                          resolve();
                          e.target.focus();
                        }}
                        onPick={(timezone) =>
                          setState((s) => ({ ...s, user: { ...s.user, timezone } }))
                        }
                      />
                    ))
                  }
                >
                  {state.user.timezone}
                  <IcoChevronDown />
                </BtnSecondary>
              </label>
            </FormGroup>
            <SectionHeading>Calendar settings</SectionHeading>
            <FormGroup prop="urlPrefix">
              <label class="flex flex-col gap-1">
                <span class="font-medium flex flex-col">
                  URL
                  <span class="text-gray-500 block">
                    A short URL where people can book meetings with you.
                  </span>
                </span>
                <span class="relative flex">
                  <input
                    type="text"
                    name="urlPrefix"
                    class="inline-ruz-input px-4 p-3 text-sm"
                    value={state.urlPrefix}
                    autofocus
                    autocomplete="off"
                    onInput={(e: any) =>
                      setState((s) => ({
                        ...s,
                        urlPrefix: e.target.value.replaceAll(/[^a-zA-Z0-9-]/g, '').toLowerCase(),
                      }))
                    }
                  />
                  <span class="absolute right-0 w-12 inset-y-0 flex items-center justify-center">
                    {status === 'loading' && <Spinner class="border-indigo-600" />}
                    {status === 'conflict' && (
                      <span class="text-red-600 text-xs flex gap-2 items-center">
                        <IcoExclamation class="size-4" />
                      </span>
                    )}
                    {status === 'verified' && (
                      <span class="text-green-600 text-xs">
                        <IcoCheck class="size-4" />
                      </span>
                    )}
                  </span>
                </span>
                {status === 'conflict' && (
                  <span class="text-red-600 text-xs flex gap-2 items-center">
                    <IcoExclamation class="size-4" />
                    {message}
                  </span>
                )}
                {status !== 'conflict' && (
                  <a
                    href={`/calendar/${state.urlPrefix}`}
                    target="_blank"
                    class="py-1 flex items-center gap-2"
                    rel="noreferrer"
                  >
                    View your booking page <IcoExternalLink />
                  </a>
                )}
              </label>
            </FormGroup>

            <InputField
              title="External calendar"
              subtitle={
                <>
                  Link your calendar (Google, Apple, Microsoft, etc). If your calendar has an ics
                  URL, paste it here, and we'll prevent any new bookings from conflicting with
                  existing events on your personal or business calendar.
                </>
              }
              name="externalIcalUrl"
              value={state.externalIcalUrl}
              onInput={eventToState(setState)}
              placeholder="https://calendar.google.com/..."
              fullWidth
            />
            <SectionHeading>Security</SectionHeading>
            <label class="flex flex-col gap-1">
              <span class="font-medium">Change your password</span>
              <BtnSecondary
                class="flex justify-between items-center gap-4 bg-white p-4 leading-none tracking-tighter text-gray-500"
                onClick={() => {
                  showChangePasswordForm();
                }}
              >
                • • • • • • • • • •
                <IcoChevronDown />
              </BtnSecondary>
            </label>
            <hr />
            <footer class="flex gap-4">
              <BtnPrimary isLoading={isSaving} class="p-3 px-4">
                Save settings
              </BtnPrimary>
              <Button
                href="/calendar/availability"
                class="text-inherit inline-flex items-center justify-center rounded-md hover:bg-gray-100 px-4 transition-all"
              >
                Cancel
              </Button>
            </footer>
          </AsyncForm>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}
