import { LoadedProps, defRoute } from '@components/router';
import { RuzcalMgmtPage } from '../mgmt-page';
import { PageContent, PageHeading, PageSection } from '../common';
import { rpx } from 'client/lib/rpx-client';
import { ZoomIntegration } from './zoom-integration';

export const route = defRoute({ authLevel: 'superadmin', load, Page });

async function load() {
  const zoomAccount = await rpx.zoom.getZoomAccount({
    forCalendar: true,
  });
  return {
    zoomAccount,
  };
}

function Page(props: LoadedProps<typeof load>) {
  const { zoomAccount } = props.state;

  return (
    <RuzcalMgmtPage title="Integrations" currentPage="integrations">
      <PageContent>
        <PageSection>
          <PageHeading title="Integrations" subtitle="Manage integrations with other services" />
          <ZoomIntegration hasAccount={zoomAccount.hasAccount} oauth={zoomAccount.oauth} />
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}
