import { useDocumentTitle } from 'client/utils/use-document-title';
import ICAL from 'ical.js';
import { ruzcal, toQueryString } from 'shared/urls';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { IcoArrowLeft, IcoArrowRight } from '@components/icons';
import { MeetingSummary } from './meeting-summary';
import { AsyncForm } from '@components/async-form';
import { Field, InputField, eventToState } from './form-helpers';
import { AutosizeText } from '@components/autosize-text';
import { BtnPrimary } from '@components/buttons';
import { ScheduleBooking } from './schedule-picker';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { useAuth } from 'client/lib/auth';
import { randomString } from 'shared/auth';
import { showError } from '@components/app-error';
import {
  useRouteParams,
  LoadedProps,
  RouteLoadProps,
  defRoute,
  useRouter,
} from '@components/router';
import { UserProfileIcon } from '@components/avatars';
import { useAsyncData } from 'client/lib/hooks';
import { getICalEventDates } from 'shared/scheduling/ical';

type Pane = 'date' | 'confirm';

/**
 * Authentication can be one of the following:
 */
type AuthState =
  | 'init' // User is not logged in, and hasn't entered an email
  | 'new' // User is not logged in, and the email is not associated with a user
  | 'returning' // User is not logged in, and the email is associated with a user
  | 'checkinbox' // User forgot password and needs to check their inbox
  | 'done'; // User is logged in.

export const route = defRoute({ isPublic: true, load, Page });

function parseRouteSchedule(params: Record<string, string>) {
  const { start, end } = params;
  if (!start || !end) {
    return;
  }
  try {
    return {
      start: new Date(start),
      end: new Date(end),
    };
  } catch (err) {
    console.error(err);
  }
}

type LoadedState = RpxResponse<typeof rpx.ruzcal.getInitialBookingState> & {
  error: undefined;
  schedule?: { start: Date; end: Date };
  baseUrl: string;
  attendeeTimeZone: string;
  attendeeName: string;
  attendeeEmail: string;
  attendeeNotes: string;
  attendeePassword: string;
  hour12: boolean;
  authState: AuthState;
};

type ErrorState = { type: 'error'; error: Error };

type State = LoadedState | ErrorState;

async function load(route: RouteLoadProps): Promise<State> {
  const { urlPrefix, urlSuffix } = route.params;
  try {
    const state = await rpx.ruzcal.getInitialBookingState({
      urlPrefix,
      urlSuffix,
    });
    const schedule = parseRouteSchedule(route.params);

    const hour12 = localStorage.ruzcalHour12
      ? localStorage.ruzcalHour12 === 'true'
      : route.params.hour12 !== 'false';

    const attendeeTimeZone =
      route.auth.user?.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      state.availability.timezone;

    return {
      ...state,
      error: undefined,
      schedule,
      baseUrl: ruzcal.newBookingUrl({ urlPrefix, urlSuffix }),
      attendeeTimeZone,
      attendeeName: '',
      attendeeEmail: '',
      attendeeNotes: '',
      attendeePassword: '',
      hour12,
      authState: route.auth.user ? 'done' : 'init',
    };
  } catch (err) {
    console.error(err);
    return {
      type: 'error',
      error: err,
    };
  }
}

type Props = LoadedProps<typeof load>;

function paneHref(opts: { pane: Pane; schedule: LoadedState['schedule']; hour12: boolean }) {
  const { pane, schedule, hour12 } = opts;
  return toQueryString(
    {
      start: schedule?.start.toISOString(),
      end: schedule?.end.toISOString(),
      hour12: String(hour12),
      pane: schedule ? pane : 'date',
    },
    '?',
  );
}

function BookingForm(props: {
  ics: undefined | string;
  state: LoadedState;
  setState: Props['setState'];
}) {
  const { state, setState } = props;
  const { schedule, eventType } = state;
  const auth = useAuth();
  const router = useRouter();

  if (!schedule) {
    return null;
  }

  if (state.authState === 'checkinbox') {
    return (
      <div class="flex grow flex-col gap-4 justify-center-center">
        <h2 class="text-xl text-sky-600">We sent you a magic link!</h2>
        <p>
          Check the inbox for {state.attendeeEmail}, and click the link there to continue booking
          this meeting.
        </p>
      </div>
    );
  }

  if (state.authState === 'init') {
    return (
      <AsyncForm
        class="flex flex-col gap-4 border-t pt-8 sm:pt-0 sm:border-0 sm:border-l sm:pl-8"
        onSubmit={async () => {
          try {
            const result = await rpx.auth.isEmailTaken({ email: state.attendeeEmail });
            setState((s) => ({ ...s, authState: result.exists ? 'returning' : 'new' }));
          } catch (err) {
            showError(err);
          }
        }}
      >
        <InputField
          name="attendeeEmail"
          fullWidth
          autoFocus
          title="Email address"
          value={state.attendeeEmail}
          onInput={eventToState(setState)}
        />
        <footer class="mt-4">
          <BtnPrimary class="inline-flex text-left gap-2 items-center p-2 px-4 rounded-full text-base min-w-40">
            Next
            <IcoArrowRight class="size-4 shrink-0" />
          </BtnPrimary>
        </footer>
      </AsyncForm>
    );
  }

  return (
    <AsyncForm
      class="flex flex-col gap-4 border-t pt-8 sm:pt-0 sm:border-0 sm:border-l sm:pl-8"
      onSubmit={async () => {
        if (!auth.user) {
          if (state.authState === 'new') {
            auth.user = await rpx.auth.registerNewUser({
              timezone: state.attendeeTimeZone,
              name: state.attendeeName,
              email: state.attendeeEmail,
              password: randomString(24, 32),
            });
          } else {
            auth.user = await rpx.auth.login({
              email: state.attendeeEmail,
              password: state.attendeePassword,
            });
          }
        }

        if (props.ics) {
          const cal = new ICAL.Component(ICAL.parse(props.ics));
          const isBooked = getICalEventDates(cal, schedule.start, schedule.end).next().value;
          if (isBooked) {
            throw new Error(`This time is no longer available.`);
          }
        }

        const result = await rpx.ruzcal.createEvent({
          end: schedule.end,
          start: schedule.start,
          eventTypeId: eventType.id,
          notes: state.attendeeNotes,
        });
        router.goto(ruzcal.existingBookingUrl({ id: result.id, success: true }));
        // Saving this until after we do the redirect, as it causes an unpleasant flicker...
        auth.setUser(auth.user);
      }}
    >
      {auth.user && (
        <>
          <span class="flex items-center gap-3">
            <UserProfileIcon user={auth.user} size="size-9" />
            <span class="flex flex-col leading-5">
              <strong>{auth.user.name}</strong>
              <span class="opacity-75">{auth.user.email}</span>
            </span>
          </span>

          <hr />
        </>
      )}
      {!auth.user && state.authState === 'new' && (
        <InputField
          name="attendeeName"
          title="Your name"
          fullWidth
          autoFocus
          value={state.attendeeName}
          onInput={eventToState(setState)}
        />
      )}
      {!auth.user && (
        <InputField
          name="attendeeEmail"
          fullWidth
          disabled
          autoFocus={!!auth.user}
          title="Email address"
          value={state.attendeeEmail}
          onInput={eventToState(setState)}
          suffix={
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setState((s) => ({ ...s, authState: 'init' }));
              }}
            >
              Change
            </button>
          }
        />
      )}
      {!auth.user && state.authState === 'returning' && (
        <>
          <InputField
            name="attendeePassword"
            title="Enter your password"
            fullWidth
            type="password"
            autoFocus
            value={state.attendeePassword}
            onInput={eventToState(setState)}
          />
          <button
            type="button"
            class="text-indigo-600 text-left hover:underline"
            onClick={async () => {
              try {
                await rpx.auth.forgotPassword({
                  email: state.attendeeEmail,
                  returnURL: location.href,
                });
                setState((s) => ({ ...s, authState: 'checkinbox' }));
              } catch (err) {
                showError(err);
              }
            }}
          >
            Click here to log in via email.
          </button>
        </>
      )}
      <Field name="notes" title="Additional notes">
        <AutosizeText
          name="attendeeNotes"
          class="ruz-input min-h-20 p-2 px-3"
          autoFocus={!!auth.user}
          value={state.attendeeNotes}
          onInput={eventToState(setState)}
        />
      </Field>
      <footer class="mt-4">
        <BtnPrimary class="inline-flex text-left gap-2 items-center p-2 px-4 rounded-full text-base">
          Schedule meeting
          <IcoArrowRight class="size-4 shrink-0" />
        </BtnPrimary>
      </footer>
    </AsyncForm>
  );
}

function ConfirmBooking(props: {
  ics: undefined | string;
  state: LoadedState;
  setState: Props['setState'];
}) {
  const { state } = props;
  const { schedule, eventType, host } = state;

  if (!schedule) {
    return null;
  }

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen">
      <section class="p-8 bg-white rounded-2xl max-w-full w-3xl an-scale-in flex-col gap-8 grid sm:grid-cols-2">
        <div>
          <a
            class="inline-flex gap-2 items-center mb-4 font-medium"
            href={paneHref({
              pane: 'date',
              schedule,
              hour12: state.hour12,
            })}
          >
            <IcoArrowLeft />
            Back
          </a>
          <MeetingSummary
            host={host}
            name={eventType.name}
            description={eventType.description}
            duration={eventType.duration}
            location={eventType.location}
            locationDetail={eventType.locationDetail}
            timeZone={state.attendeeTimeZone}
            hour12={state.hour12}
            schedule={schedule}
          />
        </div>
        <BookingForm {...props} />
      </section>
    </div>
  );
}

function LoadedPage(props: { state: LoadedState; setState: Props['setState'] }) {
  const params = useRouteParams();
  const { state, setState } = props;
  const { eventType } = props.state;
  const pane: Pane = params.pane !== 'date' && params.start ? 'confirm' : 'date';
  const ics = useAsyncData(async () => {
    if (state.host.hasICal) {
      return rpx.ruzcal.getHostIcal({ hostId: state.host.userId });
    }
  }, []);

  useDocumentTitle([eventType.name]);

  useDidUpdateEffect(() => {
    localStorage.ruzcalHour12 = state.hour12;
  }, [state.hour12]);

  if (pane === 'confirm' && state.schedule) {
    // This is a hack to make sure the schedule is set correctly.
    const schedule = parseRouteSchedule(params);
    state.schedule = schedule;
    return <ConfirmBooking ics={ics.data} state={state} setState={setState} />;
  }

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen an-scale-in">
      <section class="p-8 bg-white rounded-2xl max-w-full w-5xl">
        <div class="grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-6 gap-10">
          <section>
            <MeetingSummary
              host={state.host}
              name={eventType.name}
              description={eventType.description}
              duration={eventType.duration}
              location={eventType.location}
              locationDetail={eventType.locationDetail}
              timeZone={state.attendeeTimeZone}
              hour12={state.hour12}
            />
          </section>
          {!ics.isLoading && (
            <ScheduleBooking
              eventType={eventType}
              ics={ics.data}
              availability={state.availability}
              attendeeTimeZone={state.attendeeTimeZone}
              schedule={state.schedule}
              hour12={state.hour12}
              onScheduleChange={(schedule) => setState((s) => ({ ...s, schedule }))}
              onHour12Change={(hour12) => setState((s) => ({ ...s, hour12 }))}
              makeHref={(opts) =>
                paneHref({ pane: 'confirm', schedule: opts.schedule, hour12: state.hour12 })
              }
            />
          )}
          {ics.isLoading && (
            <>
              <section class="lg:col-span-2 sm:border-t-0 py-10 sm:pt-0 lg:p-0 lg:border-0 lg:px-4 animate-pulse rounded-2xl aspect-square grid grid-cols-1 gap-4 relative">
                <div class="bg-gray-100 rounded-2xl"></div>
                <div class="bg-gray-200 rounded-full"></div>
                <div class="bg-gray-200 rounded-full"></div>
                <div class="bg-gray-200 rounded-full"></div>
                <div class="bg-gray-200 rounded-full"></div>
                <div class="bg-gray-200 rounded-full"></div>
                <div class="bg-gray-200 rounded-full"></div>
                <div class="bg-gradient-to-b from-transparent to-white absolute inset-0"></div>
              </section>
            </>
          )}
        </div>
      </section>
    </div>
  );
}

function Page(props: LoadedProps<typeof load>) {
  if (props.state.error) {
    return (
      <div class="flex flex-col gap-8 items-center justify-center bg-gray-100 min-h-screen text-center">
        <div class="text-lg">
          <h1 class="text-2xl font-semibold">Whoops!</h1>
          {(props.state.error as Error & { statusCode?: number }).statusCode === 404 ? (
            <p>We couldn't find the booking page you were looking for.</p>
          ) : (
            <p>Something went wrong.</p>
          )}
        </div>
        <footer>
          <BtnPrimary href="/" class="inline-flex gap-2 items-center rounded-full">
            <IcoArrowLeft />
            Go to home page
          </BtnPrimary>
        </footer>
      </div>
    );
  }
  return <LoadedPage state={props.state} setState={props.setState} />;
}
