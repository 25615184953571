import type { RouteRule } from '@components/router/async-router';

export const routes: RouteRule[] = [
  [() => import('./cal-public-meeting-types'), 'meet/:urlPrefix'],
  [() => import('./cal-public'), 'meet/:urlPrefix/:urlSuffix'],
  [() => import('./cal-public-scheduled'), 'calendar/bookings/:eventId'],
  [() => import('./cal-bookings-list'), 'calendar', 'calendar/bookings'],
  [() => import('./cal-availability-list'), 'calendar/availability', 'calendar/overrides'],
  [() => import('./cal-availability-edit'), 'calendar/availability/:id'],
  [() => import('./cal-meeting-types-edit'), 'calendar/meeting-types/:id'],
  [() => import('./cal-meeting-types-list'), 'calendar/meeting-types'],
  [() => import('./cal-overrides-edit'), 'calendar/overrides/:id'],
  [
    () => import('./cal-integrations'),
    'calendar/integrations',
    'calendar/integrations/:integrationType',
  ],
  [() => import('./cal-settings'), 'calendar/settings'],
  [() => import('./cal-not-found'), 'calendar/*', 'meet/*'],
];
