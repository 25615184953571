import { UserProfileIcon } from '@components/avatars';
import {
  IcoArrowLeft,
  IcoArrowRight,
  IcoCalendar,
  IcoDocument,
  IcoDownload,
  IcoExternalLink,
  IcoMapPin,
  IcoVideoCamera,
} from '@components/icons';
import { rpx } from 'client/lib/rpx-client';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { BulletIco } from './bullet-ico';
import { ScheduleSummary } from './schedule-summary';
import { BtnPrimary, Button } from '@components/buttons';
import { useCurrentUser } from 'client/lib/auth';
import { isNowish } from './dateutil';
import { BaseDialog, showDialog, showGlobal } from '@components/dialog';
import { showError } from '@components/app-error';
import { showToast } from '@components/toaster';
import { showModalForm } from '@components/modal-form';
import { ScheduleBooking } from './schedule-picker';
import { useAsyncState } from 'client/lib/hooks';
import { useMemo } from 'react';
import { EventRow } from 'server/types/cal-schema';
import { eventToIcal } from 'shared/scheduling/ical';
import { InputField, eventToState } from './form-helpers';
import { useState } from 'preact/hooks';
import { AsyncForm } from '@components/async-form';
import { LoadedProps, RouteLoadProps, defRoute } from '@components/router/async-router';

export const route = defRoute({ load, Page });

async function showLoginModal(auth: RouteLoadProps['auth']) {
  return showGlobal(({ resolve }) => {
    const [state, setState] = useState({
      email: '',
      password: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    useDocumentTitle(['Sign in']);
    return (
      <AsyncForm
        class="max-w-screen flex flex-col items-center justify-center gap-6 p-10 fixed inset-0 z-10 bg-gray-100"
        onSubmit={async () => {
          setIsLoading(true);
          try {
            auth.user = await rpx.auth.login(state);
            resolve(auth.user);
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <div class="flex flex-col gap-6 p-8 bg-white rounded-2xl shadow-lg font-medium">
          <header>
            <h1 class="text-xl font-semibold">Sign in</h1>
            <p>You must be signed in to view this meeting.</p>
          </header>
          <InputField
            name="email"
            title="Email address"
            fullWidth
            type="email"
            autoFocus
            value={state.email}
            onInput={eventToState(setState)}
          />
          <InputField
            name="password"
            title="Password"
            fullWidth
            type="password"
            value={state.password}
            onInput={eventToState(setState)}
          />
          <BtnPrimary isLoading={isLoading} class="rounded-full mt-2">
            Sign in
          </BtnPrimary>
          <footer class="border-t pt-6">
            <h2 class="text-gray-600">Forgot your password?</h2>
            <button
              type="button"
              class="text-indigo-600 text-left hover:underline"
              onClick={async () => {
                try {
                  await rpx.auth.forgotPassword({
                    email: state.email,
                    returnURL: location.href,
                  });
                  setState((s) => ({ ...s, authState: 'checkinbox' }));
                } catch (err) {
                  showError(err);
                }
              }}
            >
              Sign in with a magic email link.
            </button>
          </footer>
        </div>
      </AsyncForm>
    );
  });
}

async function load(props: RouteLoadProps) {
  async function tryLoad() {
    const result = await rpx.ruzcal.getEvent({
      eventId: props.params.eventId,
    });
    return {
      ...result,
      isSuccess: !!props.params.success,
      event: {
        ...result.event,
        start: new Date(result.event.start),
        end: new Date(result.event.end),
      },
    };
  }
  while (!props.auth.user) {
    try {
      return await tryLoad();
    } catch (err) {
      if (err.statusCode !== 403) {
        throw err;
      }
      await showLoginModal(props.auth);
    }
  }
  return await tryLoad();
}

type Props = LoadedProps<typeof load>;
type State = Props['state'];

function jitsiUrl({
  eventType,
  event,
  attendeeName,
}: {
  eventType: State['eventType'];
  event: State['event'];
  attendeeName: string;
}) {
  return `https://meet.jit.si/${eventType.urlSuffix}-${
    event.id
  }#userInfo.displayName=${encodeURIComponent(
    `"${attendeeName}"`,
  )}&config.subject=${encodeURIComponent(
    `"${eventType.name}"`,
  )}&config.prejoinConfig.enabled=false`;
}

function RescheduleModal({
  close,
  state,
  onScheduleChange,
}: {
  state: State;
  onScheduleChange(event: EventRow): void;
  close(): void;
}) {
  const user = useCurrentUser();
  const { host, eventType, event } = state;
  const [data, setData] = useAsyncState(async () => {
    const result = await rpx.ruzcal.getInitialBookingState({
      urlPrefix: host.urlPrefix,
      urlSuffix: eventType.urlSuffix,
    });
    return {
      ...result,
      schedule: { start: event.start, end: event.end },
      hour12: true,
    };
  }, []);
  const attendeeTimeZone = useMemo(() => {
    return (
      user?.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      data?.availability.timezone ||
      'America/New_York'
    );
  }, [data?.availability.timezone, user?.timezone]);

  return (
    <BaseDialog onClose={close} contentWidth>
      {!data && <p>Loading...</p>}
      {data && (
        <section class="flex flex-col sm:gap-6 gap-10 sm:p-10 sm:w-2xl lg:w-4xl max-w-screen overflow-auto">
          <header>
            <h2 class="font-semibold text-base leading-4">Reschedule {eventType.name}</h2>
          </header>
          <div class="lg:grid lg:grid-cols-3 sm:gap-6 gap-10">
            <ScheduleBooking
              eventType={eventType}
              availability={data.availability}
              attendeeTimeZone={attendeeTimeZone}
              compact
              schedule={data.schedule}
              hour12={data.hour12}
              onScheduleChange={(schedule) => setData((s) => ({ ...s!, schedule }))}
              onHour12Change={(hour12) => setData((s) => ({ ...s!, hour12 }))}
              onClick={async (opts) => {
                const ok = await showDialog({
                  mode: 'info',
                  title: 'Reschedule your meeting?',
                  children: (
                    <>
                      Would you like to change your meeting to:
                      <ScheduleSummary schedule={opts.schedule} hour12={data.hour12} />
                    </>
                  ),
                  confirmButtonText: 'Yes. Reschedule.',
                  cancelButtonText: 'Keep the current schedule.',
                });
                if (!ok) {
                  return close();
                }

                const result = await rpx.ruzcal.updateEvent({
                  id: event.id,
                  start: opts.schedule.start,
                  end: opts.schedule.end,
                });
                onScheduleChange(result);
                showToast({
                  type: 'ok',
                  title: 'Meeting rescheduled',
                  message: 'Your changes have been saved.',
                });
                close();
              }}
            />
          </div>
        </section>
      )}
    </BaseDialog>
  );
}

function MeetingUser({
  user,
  isHost,
}: {
  user: { name: string; email?: string };
  isHost?: boolean;
}) {
  return (
    <li class="flex items-center gap-4">
      <UserProfileIcon user={user} size="size-10" />
      <span class="flex flex-col">
        <span>
          {user.name}
          {isHost && (
            <span class="bg-blue-500 text-white px-1 p-0.5 text-xs font-semibold rounded-sm ml-2">
              Host
            </span>
          )}
        </span>
        {user.email && (
          <a class="text-inherit" href={`mailto:${encodeURIComponent(user.email)}`}>
            {user.email}
          </a>
        )}
      </span>
    </li>
  );
}

/**
 * Matches URLs and emails and turns them into links.
 */
function Linkify(props: { text: string }) {
  const regex = /(https:\/\/[^\s]+)|(mailto:[^\s]+)/gi;
  const strs = props.text.split(regex);
  return (
    <>
      {strs.map((s, i) => {
        const isUrl = s?.startsWith('https://') || s?.startsWith('mailto:');
        return (
          <span key={i}>
            {isUrl && (
              <a href={s} target="_blank" rel="noreferrer" class="text-indigo-600 underline">
                {s}
              </a>
            )}
            {!isUrl && s}
          </span>
        );
      })}
    </>
  );
}

function Page(props: Props) {
  const user = useCurrentUser();
  const { eventType, event, attendee, host } = props.state;
  const isHost = user?.id === host.userId;
  const jitsiHref =
    eventType.location === 'jitsi'
      ? jitsiUrl({ eventType, event, attendeeName: user?.name ?? attendee.name })
      : undefined;

  useDocumentTitle(['Meeting scheduled!']);

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen an-scale-in">
      <section class="p-8 bg-white rounded-2xl max-w-full w-2xl flex flex-col gap-6">
        <header>
          {isHost && (
            <a href="/calendar/bookings" class="flex items-center gap-2 mb-2">
              <IcoArrowLeft /> All bookings
            </a>
          )}
          <h1 class="text-2xl font-semibold">
            {props.state.isSuccess ? 'Meeting scheduled!' : 'Meeting details'}
          </h1>
          {props.state.isSuccess && (
            <p class="text-gray-500">
              We sent a calendar invitation and meeting details to <em>{attendee.email}</em>.
            </p>
          )}
          {!props.state.isSuccess && eventType.description && (
            <p class="text-gray-500">{eventType.description}</p>
          )}
        </header>
        <section class="flex flex-col border-y py-6 gap-8">
          <div class="flex flex-col gap-4">
            <h2 class="font-semibold text-lg text-gray-700">{eventType.name}</h2>
            <BulletIco Ico={IcoCalendar} multiline>
              <ScheduleSummary schedule={event} hour12 />
            </BulletIco>
            {eventType.location === 'jitsi' && <BulletIco Ico={IcoVideoCamera}>Jitsi</BulletIco>}
            {event.notes && (
              <BulletIco Ico={IcoDocument} multiline>
                <span class="whitespace-pre-wrap">{event.notes}</span>
              </BulletIco>
            )}
            {eventType.location === 'zoom' && (
              <BulletIco Ico={IcoMapPin} multiline>
                <a class="flex items-center text-inherit hover:text-indigo-700 hover:underline gap-1">
                  Zoom Video
                  <IcoExternalLink />
                </a>
              </BulletIco>
            )}
            {eventType.location === 'external' && eventType.locationDetail && (
              <BulletIco Ico={IcoMapPin} multiline>
                <span class="whitespace-pre-wrap">
                  <Linkify text={eventType.locationDetail.external} />
                </span>
              </BulletIco>
            )}
            <Button
              class="hover:text-indigo-700 hover:underline"
              onClick={() => {
                const attachment = eventToIcal({
                  attendee,
                  eventType,
                  host,
                  event,
                  eventURL: location.href,
                  isCanceled: false,
                });
                const url = URL.createObjectURL(attachment.data);
                const a = document.createElement('a');
                a.href = url;
                a.download = attachment.filename;
                a.click();
                setTimeout(() => URL.revokeObjectURL(url), 1000);
              }}
            >
              <BulletIco Ico={IcoDownload}>Export to calendar</BulletIco>
            </Button>
          </div>
          <ul class="leading-snug flex flex-col gap-6 col-span-3">
            <MeetingUser user={host} isHost />
            <MeetingUser user={attendee} />
          </ul>
          <footer>
            {eventType.location === 'jitsi' && (isHost || isNowish(event)) && (
              <BtnPrimary class="rounded-full p-3 px-6 text-base" href={jitsiHref}>
                <span class="flex items-center gap-2">
                  {isHost ? `Launch meeting` : `Join meeting`}
                  <IcoArrowRight />
                </span>
              </BtnPrimary>
            )}
            {eventType.location === 'zoom' && (
              <BtnPrimary class="rounded-full p-3 px-6 text-base" href="test">
                {isHost ? `Launch meeting` : `Join meeting`}
                <IcoArrowRight class="w-4 h-4 ml-2 " />
              </BtnPrimary>
            )}
          </footer>
        </section>
        <footer>
          You can{' '}
          <button
            type="button"
            class="text-indigo-600 underline"
            onClick={() => {
              showModalForm(({ resolve }) => (
                <RescheduleModal
                  close={resolve}
                  state={props.state}
                  onScheduleChange={(event) =>
                    props.setState((s) => ({
                      ...s,
                      event: {
                        ...event,
                        start: new Date(event.start),
                        end: new Date(event.end),
                      },
                    }))
                  }
                />
              ));
            }}
          >
            reschedule
          </button>{' '}
          or{' '}
          <button
            type="button"
            class="text-indigo-600 underline"
            onClick={async () => {
              try {
                const ok = await showDialog({
                  mode: 'warn',
                  title: 'Cancel meeting?',
                  children: 'Are you sure you want to cancel this meeting?',
                  confirmButtonText: 'Yes. Cancel the meeting.',
                  cancelButtonText: 'Keep the meeting.',
                });
                if (!ok) {
                  return;
                }
                const { bookingUrl } = await rpx.ruzcal.cancelEvent({
                  id: event.id,
                });
                showToast({
                  title: 'Meeting canceled',
                  message: 'The meeting has been canceled.',
                  type: 'ok',
                });
                props.router.goto(bookingUrl);
              } catch (err) {
                showError(err);
              }
            }}
          >
            cancel
          </button>{' '}
          any time before the meeting starts.
        </footer>
      </section>
    </div>
  );
}
