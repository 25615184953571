import { showError } from '@components/app-error';
import { Case } from '@components/conditional';
import { IcoCheckCircle, IcoZoom } from '@components/icons';
import { LoadingIndicator } from '@components/loading-indicator';
import { useRouteParams, useRouter } from '@components/router';
import { DefaultSpinner } from '@components/spinner';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useEffect, useState } from 'preact/hooks';
import { IntegrationRow } from './integration-value-item';

const store = rpx.zoom;

/**
 * If this page is being displayed as a return redirect from Zoom connect, it will look something
 * like this: /account/integrations/zoom?code=authCode
 * This component will render and finish the Zoom connect process.
 */
function FinishOauth({ code, onError }: { code: string; onError: () => void }) {
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    async function init() {
      try {
        await store.createZoomAccount({ code });
        // Hide the Zoom OAuth URL params so that refresh works.
        router.rewrite('/account/integrations/zoom-oauth');
      } catch (err) {
        showError(err);
        onError();
      } finally {
        setIsLoading(false);
      }
    }
    init();
  }, []);

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <p>
        {isLoading
          ? 'Verifying Zoom account...'
          : `Your Zoom account is connected. You're ready to create Zoom meetings!`}
      </p>
    </>
  );
}

function ConnectPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<RpxResponse<typeof store.getZoomAccount> | undefined>(
    undefined,
  );

  useEffect(() => {
    async function init() {
      try {
        const result = await store.getZoomAccount({});
        setResponse(result);
      } catch (err) {
        showError(err);
      } finally {
        setIsLoading(false);
      }
    }
    init();
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <p class="mb-2">
        {response?.hasAccount
          ? 'Zoom is properly configured. You can change Zoom accounts by clicking the button below.'
          : 'Connect with Zoom to create meetings on Zoom.'}
      </p>
      <a
        class="bg-indigo-600 text-white p-4 py-3 inline-flex items-center justify-center rounded-sm hover:bg-indigo-500"
        onClick={() => setIsLoading(true)}
        href={response?.oauth}
      >
        <span class="flex items-center">
          <Case when={response?.hasAccount} fallback={'Connect with'}>
            <IcoCheckCircle class="w-6 h-6 mr-1" /> Integrated with{' '}
          </Case>
          <IcoZoom class="h-4 ml-2" fill="white" />
        </span>
      </a>
    </div>
  );
}

export function ZoomIntegration() {
  const { integrationType, code: paramCode } = useRouteParams();
  const code = integrationType === 'zoom-oauth' ? paramCode : undefined;
  const [hasError, setHasError] = useState(false);

  return (
    <IntegrationRow icon={<IcoZoom class="h-6" />}>
      {code && !hasError && <FinishOauth code={code} onError={() => setHasError(true)} />}
      {(!code || hasError) && <ConnectPage />}
    </IntegrationRow>
  );
}

/**
 * Handle the Stripe oath redirect. This needs to be public, since the user
 * may not be authenticated in core Ruzuku (or even have a core Ruzuku account).
 * So, we reditect to the appropriate tenant, and then finish processing there.
 */
export function ZoomOauthRedirect() {
  const { tenantId, code } = useRouteParams();
  useAsyncEffect(async () => {
    const domain = await store.getTenantDomain({ tenantId: tenantId });
    // Redirect to the tenant, and the oauth-finalization route
    const redirectTo = `https://${domain}/account/integrations/zoom-oauth?code=${code}`;
    location.assign(redirectTo);
  }, []);

  return (
    <div class="text-center p-8">
      <DefaultSpinner />
    </div>
  );
}
