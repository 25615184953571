import { useEffect, useState } from 'preact/hooks';
import { showDialog } from '@components/dialog';
import { showToast } from '@components/toaster';
import { IcoVideoCamera } from '@components/icons';
import { IntegrationRow } from './integration-row';
import { rpx } from 'client/lib/rpx-client';
import { useRouteParams, useRouter } from '@components/router';
import { showError } from '@components/app-error';
import { Spinner } from '@components/spinner';

type Props = {
  hasAccount: boolean;
  oauth: string;
};

/**
 * If this page is being displayed as a return redirect from Zoom connect, it will look something
 * like this: /calendar/integrations/zoom?code=authCode
 * This component will render and finish the Zoom connect process.
 */
function FinishOauth({ code, onError }: { code: string; onError: () => void }) {
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    async function init() {
      try {
        await rpx.zoom.createZoomAccount({ code });
        //Hide the Zoom OAuth URL params so that refresh works.
        router.rewrite('/calendar/integrations/zoom-oauth');
      } catch (err) {
        showError(err);
        onError();
      } finally {
        setIsLoading(false);
      }
    }
    init();
  }, []);

  if (isLoading) {
    return (
      <div class="flex items-center p-4 gap-4 bg-white rounded-2xl">
        <Spinner class="border-sky-500" />
        Processing Zoom...
      </div>
    );
  }
  return null;
}

export function ZoomIntegration(props: Props) {
  const { integrationType, code: paramCode } = useRouteParams();
  const code = integrationType === 'zoom' ? paramCode : undefined;
  const [hasAccount, setHasAccount] = useState(props.hasAccount);
  const [hasError, setHasError] = useState(false);

  if (code && !hasError) {
    return <FinishOauth code={code} onError={() => setHasError(true)} />;
  }

  return (
    <IntegrationRow
      name="Zoom"
      description="Host your meetings on Zoom"
      icon={<IcoVideoCamera class="w-5 h-5 text-white" />}
      isInstalled={hasAccount}
      onIntegrate={async () => {
        if (hasAccount) {
          const isConfirmed = await showDialog({
            mode: 'warn',
            title: 'Re-integrate Zoom',
            children: `This integration is shared with your courses. Do you want to continue?`,
            confirmButtonText: 'Re-integrate',
          });
          if (!isConfirmed) {
            return;
          }
        }
        window.location.assign(props.oauth);
      }}
      onDisconnect={async () => {
        if (hasAccount) {
          const isConfirmed = await showDialog({
            mode: 'warn',
            title: 'Disconnect Zoom',
            children: `This integration is shared with your courses. Do you want to continue?`,
            confirmButtonText: 'Disconnect',
          });
          if (!isConfirmed) {
            return;
          }
        }
        await rpx.zoom.disconnect();
        showToast({
          type: 'ok',
          title: 'Zoom disconnected',
          message: 'Your Zoom account has been disconnected.',
        });
        setHasAccount(false);
      }}
    />
  );
}
